.login {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.login__aside {
  width: 50%;
  background-color: #e02620;
  background-image: url("../assets/woman-academiya-transparent-opt.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 440px 660px;
}

.login__logo {
  display: block;
  margin: 90px auto 0 auto;
}

.login__main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
}

.login__logo_mobile {
  display: none;
}

.login__title {
  font-size: 2.3rem;
  font-weight: 800;
}

.login__subtitle {
  margin-top: 12px;
  font-size: 1.2rem;
  font-weight: 400;
}

.login__form {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 430px;
  row-gap: 30px;
}

.login__input {
  padding: 8px 0;
  font-size: 1.2rem;
  border: none;
  border-bottom: 1px solid #e0e0e0;
}

.login__submit {
  padding: 20px 0;
  font-size: 1rem;
  color: #fff;
  background-color: #e02620;
  border-radius: 6px;
}

.login__submit:hover {
  background-color: #ee433d;
}

.login__submit:active {
  background-color: #cc1e18;
}

@media (width <= 1000px) {
  .login__aside {
    display: none;
  }

  .login__main {
    width: 100%;
  }

  .login__logo_mobile {
    position: absolute;
    top: 30px;
    display: inline;
  }

  .login__title {
    text-align: center;
    font-size: 25px;
  }

  .login__subtitle {
    text-align: center;
    font-size: 15px;
  }

  .login__form {
    width: 300px;
  }
}
