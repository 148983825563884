* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Exo, Roboto, "Open Sans", "Helvetica Neue", sans-serif;
}

button {
  background-color: #fff;
  border: none;
  cursor: pointer;
}

input,
button {
  outline: none;
}

a {
  color: #000;
  text-decoration: none;
}

li {
  list-style-type: none;
}
