.course {
  padding-bottom: 75px;
}

.course__title {
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
}

.course__summary {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  width: fit-content;
  padding: 12px 22px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.course__info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 60px;
}

.summary__finished_full {
  width: 180px;
}

.subtext {
  font-weight: 700;
  font-size: 8px;
}

.summary__title {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  white-space: nowrap;
  color: #000;
}

.summary__info {
  display: flex;
  column-gap: 5px;
  margin-top: 7px;
  align-items: flex-end;
}

.finished__result {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #000;
}

.finished__result_best {
  color: #21b56e;
}

.result__preposition {
  font-size: 12px;
}

.result__preposition_finished {
  font-weight: 700;
}

.finished__total {
  font-weight: 700;
  font-size: 12px;
  color: #7a7a7a;
}
/* 
.table__cell_result {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
} */

.cell__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 12px;
}

.result {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
}

.result__score {
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: fit-content;
}

.result__count {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
}

.result__count_success {
  color: #37cb97;
}

.result__count_warning {
  color: rgb(241, 194, 107);
}

.result__count_danger {
  color: darkorange;
}

.result__count_failed {
  color: #e02620;
}

.result__count_total {
  color: #64748b;
}

.result__type {
  font-size: 12px;
  line-height: 24px;
  color: #7a7a7a;
}

.summary__username {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  background-color: rgba(237, 249, 245, 0.58);
  border-radius: 6px;
}

.summary__first-name,
.summary__last-name {
  font-size: 10px;
  line-height: 13px;
  color: #21b56e;
}

.forms {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
}

.new-employee-button {
  padding: 11px 12px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #fff;
  background-color: #e02620;
  border: none;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
}

.search {
  width: 262px;
  height: 38px;
  padding-left: 10px;
  font-size: 12px;
  background-image: url("../assets/search.png");
  background-position: center right 12px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  outline: none;
}

.search::placeholder {
  font-size: 12px;
  line-height: 24px;
  color: #959697;
}

.table {
  margin-top: 29px;
  width: 100%;
  border-collapse: collapse;
}

.table__title {
  position: relative;
  padding: 7px 30px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #7a7a7a;
  white-space: nowrap;
  background-color: #f9fafb;
}

.table__title_left {
  text-align: left;
}

.table__title > span {
  font-size: 12px;
}

.table__title .check__box {
  background-color: #fff;
}

.table__sort {
  margin-right: 3px;
}

.table__cell {
  position: relative;
  padding: 20px 30px;
  border-bottom: 1px solid #eff1f3;
}

.table__cell > div {
  font-size: 14px;
  line-height: 24px;
}

.table__cell > .result {
  margin: 0 auto;
}

.table__cell_centered {
  text-align: center;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 24px;
}

.date__full {
  height: 49px;
}

.user {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.user__photo {
  width: 30px;
  height: 30px;
  background-color: #f9fafb;
  border-radius: 50%;
  opacity: 0.1;
}

.status__wrapper {
  height: 44px;
  display: flex;
  align-items: center;
}

.status {
  width: 96px;
  height: 24px;
  padding: 0px 7px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 6px;
}

.status_success {
  color: #21b56e;
  background-color: rgba(237, 249, 245, 0.58);
}

.status_failure {
  color: #e02620;
  background-color: rgba(255, 242, 243, 0.54);
}

.status__marker {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.status__marker_activated {
  background-color: #37cb97;
}

.status__marker_not-paid {
  background-color: #cb3752;
}

.status__text {
  font-size: 12px;
}

.status__text_activated {
  color: #37cb97;
}

.status__text_not-paid {
  color: #cb3752;
}

.access-key {
  width: 100px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.access-key__copy {
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.access-key__key {
  font-size: 14px;
}

.access-key__plug {
  font-size: 28px;
  color: #959697;
}

.counter {
  display: flex;
  align-items: center;
  height: 49px;
  font-size: 14px;
}

.users-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}

.users-inputs {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.users-search {
  width: 330px;
}

.users-list {
  max-height: 235px;
  overflow: auto;
}

.users-item {
  display: flex;
  align-items: center;
  column-gap: 55px;
  padding: 10px 15px;
  border: 1px solid #eff1f3;
  cursor: pointer;
}

@media (width <= 1000px) {
  .summary {
    justify-content: space-between;
    row-gap: 20px;
  }

  .forms {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
  }

  .search {
    width: 100%;
  }

  .summary div {
    flex-basis: 45%;
  }

  .new-employee-button {
    width: 100%;
    padding: 12px 20px;
  }

  .new-employee-button.pay {
    width: 45%;
  }

  .table {
    width: 100%;
  }

  .table__wrapper {
    width: calc(100vw - 20px);
    overflow-x: auto;
  }

  .table__title {
    padding: 15px;
    white-space: nowrap;
  }

  .table__cell {
    padding: 15px;
  }

  .result {
    margin: 0;
  }
}
