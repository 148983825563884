.course__header {
  display: flex;
  justify-content: space-between;
}

.title__wrapper {
  display: flex;
  align-items: center;
  column-gap: 36px;
}

.course-selector {
  width: 128px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
}

.course-selector_active {
  background: #e02620;
  color: #fff;
}

.users__list {
  padding-bottom: 75px;
}

.new-employee {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}

.new-employee__inputs {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.new-employee__label {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.new-employee__input {
  width: 330px;
  height: 45px;
  padding-left: 10px;
  border: 1px solid #eff1f3;
}

.new-employee-button {
  width: fit-content;
}

.table__title_check {
  text-align: left !important;
}

.confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  padding: 0 60px;
}

.confirm__text {
  font-size: 16px;
}

.confirm__buttons {
  display: flex;
  column-gap: 15px;
}

.confirm__button {
  padding: 11px 40px;
  border-radius: 4px;
}

.confirm__button_accept {
  color: #fff;
  background-color: #e02620;
}

.confirm__button_decline {
  color: #000;
}

.user__buttons {
  display: flex;
  column-gap: 10px;
}

.footer {
  margin-left: -29px;
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding: 18px 30px;
  background: #f9fafb;
  transform: translateY(75px);
  transition: 0.4s all;
}

.footer_active {
  display: block;
  transform: translateY(0);
}

.footer__buttons {
  display: flex;
  column-gap: 15px;
}

.button_additional {
  padding: 11px 40px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  background-color: #64748b;
  border-radius: 4px;
}

@media (width <= 1000px) {
  .footer {
    bottom: 50px;
    margin-left: -11px;
  }

  .footer__buttons {
    justify-content: space-around;
  }

  .button_additional {
    width: 45%;
  }

  .confirm {
    padding: 0 30px;
  }
}
