.container {
  padding-top: 56px;
  padding-right: 50px;
  padding-left: 250px;
}

.edit {
  width: 20px;
  height: 20px;
}

@media (width <= 1000px) {
  .container {
    margin-left: 0;
    padding-right: 10px;
    padding-left: 10px;
  }
}
