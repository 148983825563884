.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 1;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modal__content {
  max-height: 75vh;
  overflow-y: auto;
  padding: 35px 30px 30px;
  border-radius: 4px;
  background-color: #fff;
  transform: scale(0.5);
  transition: 0.4s all;
}

.modal__content.active {
  transform: scale(1);
}
