.check__input {
  appearance: none;
}

.check__box {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #f9fafb;
  border: 1px solid #e02620;
  border-radius: 3px;
}

.check__box::before {
  position: absolute;
  top: 1px;
  left: 1px;
  content: "";
  width: 11px;
  height: 11px;
  background-color: #f9fafb;
  border-radius: 3px;
}

.check__box_all,
.check__box_all::before {
  background-color: #fff;
}

.check__input:checked + .check__box {
  background-color: #f9fafb;
  border: 1px solid #e02620;
  border-radius: 3px;
}

.check__input:checked + .check__box::before {
  background-color: #e02620;
}
