.container {
  margin-bottom: 60px;
}

.title {
  margin-bottom: 69px;
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
}

.courses {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.course {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;
  width: 550px;
  padding: 16px;
  background-color: #f9fafb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.course__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.course__title {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.course__type {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.course__price {
  width: 140px;
  height: 31px;
  padding: 6px 25px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  background-color: #fff;
  border-radius: 31px;
}

.course__body {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
}

.course__subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #7a7a7a;
}

.course__info {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.course__description {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.course__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.course__participate {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #7a7a7a;
}

.course__members {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: #000;
}

.course__buttons {
  display: flex;
  column-gap: 18px;
}

.button {
  display: block;
  width: fit-content;
  padding: 11px 12px;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
}

.button_primary {
  font-weight: 600;
  color: #fff;
  background-color: #e02620;
  border-radius: 6px;
}

.button_primary:hover {
  background-color: #cb211c;
}

.button_primary:active {
  background-color: #b61d18;
}

.button_secondary {
  font-weight: 500;
  color: #e02620;
  border: 1px solid #e8e9e9;
  border-radius: 6px;
}

.button_secondary:hover {
  border: 1px solid #cb211c;
}

.button_secondary:active {
  color: #b61d18;
  border: 1px solid #b61d18;
}
