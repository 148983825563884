.program {
  margin-top: 34px;
  display: flex;
  column-gap: 84px;
}

.back {
  display: block;
  width: fit-content;
  padding: 11px 12px;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  font-weight: 500;
  color: #e02620;
  border: 1px solid #e8e9e9;
  border-radius: 6px;
}

.back:hover {
  border: 1px solid #cb211c;
}

.back:active {
  color: #b61d18;
  border: 1px solid #b61d18;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #7a7a7a;
}

.course-title {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.best {
  margin-top: 15px;
}

.best__result {
  margin: 0;
}

.best__title {
  padding-right: 15px;
  padding-bottom: 6px;
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
}

.program {
  padding-bottom: 20px;
}

.programm__cell {
  vertical-align: top;
  padding-right: 13px;
  padding-bottom: 24px;
}

.research {
  margin-top: 12px;
  width: 584px;
  height: 166px;
  padding: 16px;
  font-size: 12px;
  font-weight: 400;
  background-color: #f9fafb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.progress {
  display: flex;
}

.progress__bar {
  position: relative;
  margin-top: 7px;
  width: 217px;
  height: 8px;
  background-color: #f9fafb;
  border-radius: 1px;
}

.progress__fill {
  position: absolute;
  content: "";
  display: block;
  height: 8px;
  background-color: #e02620;
  border-radius: 1px;
}

.progress__description {
  display: flex;
  justify-content: space-between;
  width: 217px;
}

.description {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #7a7a7a;
}

.progress__result {
  margin-left: 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  color: #7a7a7a;
}

.errors-count {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.question {
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.answer-item {
  margin-bottom: 10px;
}

.answer {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 20px;
}

.answer_correct {
  color: #21b56e;
}

.answer_incorrect {
  color: #e02620;
}

.correct-answer {
  font-style: italic;
}
