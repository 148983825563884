.header {
  padding: 30px 0;
}

.logo {
  margin-left: 7.5px;
  display: flex;
}

.nav {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 100vh;
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: #f9fafb;
  z-index: 1;
}

.nav__list {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  row-gap: 70px;
}

.nav__link {
  display: flex;
  align-items: center;
  width: 160px;
  height: 42px;
  padding-left: 48px;
  border-radius: 13px 0 0 13px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #959697;
  background-repeat: no-repeat;
  background-position: 10px center;
}

.nav__link_active {
  color: #e02620;
  background-color: #fff;
}

.nav-courses {
  background-image: url("../../assets/courses.svg");
}

.nav-courses-active {
  background-image: url("../../assets/courses-active.png");
}

.nav-users {
  background-image: url("../../assets/users.svg");
}

.nav-users-active {
  background-image: url("../../assets/users-active.png");
}

.nav-payment {
  background-image: url("../../assets/payment.svg");
}

.nav-payment-active {
  background-image: url("../../assets/payment-active.png");
}

.nav__icon {
  width: 25px;
  height: 25px;
}

.nav__logout {
  background-color: transparent;
  width: 24px;
}

@media (width <= 1000px) {
  .nav {
    top: auto;
    bottom: 0;
    width: 100vw;
    height: auto;
    padding: 0 80px;
  }

  .nav__list {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  .nav__link {
    width: 50px;
    height: 50px;
    border-top: 3px solid transparent;
    border-left: none;
  }

  .nav__link_active {
    border-top: 3px solid #e02620;
    border-left: none;
  }

  .nav__logout {
    display: none;
  }
}
